import React,  { Component } from "react";
import Axios from "axios";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import Layout from "../Layout/Layout";

import MessageHeader from "../Layout/MessageHeader";
import PhotoPreview from "../Photo/PhotoPreview";
import FileUploader from "../FileUpload/FileUploader";
import Spinner from "react-bootstrap/Spinner"

import partList from "../ClickableCar/PartList";

class Carpic extends Component {
    damageIcon =  "/images/icons/detail2.png";
    documentIcon = "/images/icons/dokumente.png";
    
    constructor (props) {
        super(props);        
        let contractKey = null;//"FQAIUFJK";

        const photoRequired = true;
        
        const imageDefaultPath = process.env.PUBLIC_URL+"/images/";
        
        let myState = -1;
        const carType = localStorage.getItem(contractKey+"_cartype");
        
        if (carType) {
            myState = parseInt(carType,10);
        }            

        //prod, test, dev
        this.state = { 
            partList: partList,
            fileMaxSizeMultiPhoto: 2,
            fileMaxSizeMultiVideo: 15,
            // systemID: "prod",
            // dataUrl: "https://carpic.svpro.de/backend.new/getdata.php?key=",//PROD            
            // uploadTarget: "https://carpic.svpro.de/backend.new/",//PROD

            systemID: "dev",
            dataUrl: "https://carpic-dev.svpro.de/backend/getdata.php?key=",//DEV            
            uploadTarget: "https://carpic-dev.svpro.de/backend/",//DEV
            
            // systemID: "devgs",
            // dataUrl: "https://carpic-test.svpro.de/backend/getdata.php?key=",//DEV            
            // uploadTarget: "https://carpic-test.svpro.de/backend/",//DEV

            impressum: "",
            datenschutz: "",

            modalVisible: false,

            view: "main",
            jitsiStarted: false,
            appInitialized: false,
            dataLoaded: false,   
            transferFinished: false,   
            networkError: false,
            generalError: 0,
            errorMessage: "",
            uploading: false,     
            jobCanceled: false,
            
            imageDefaultPath: imageDefaultPath,                        
            svp: null,
            auszahlung_wbw_netto: null,
            injuranceName: null,
            injurenceLogo: null,
            haftpflicht: "N",
            schadenausweitung: "N",
            key: contractKey,
            fzg_aufbauart: null,
            fzg_fahrzeugart: null,
            dsgvoAccepted: false,
            /* dataComplete: false,  */
            chargeType: "-2",//abrechnungswunschauswahl
            vorSchaden: -1,//abrechnungswunschauswahl
            vorschadenDetail: -1,
            vorschadenAmount: 0.0,
            choose_copart: 0,
            customer_mail: "",
            customer_phone: "",
            bankData: {
                firstname: "",
                familyname: "",                
                iban: "",                
            },
            photoSize: {
                width: 480,
                height: 360
            },
            contractData: {
                contructNumber: 1234
            },                
            carType: myState,  
            currentPhoto: null,   
            moreButtons: true,   
            damageDetailPhotos: 2,
            damageDetailPhotoMax: 5,
            damageDetailAddButton: true,
            documents: 1,
            documentMax: 5,
            documentAddButton: true,
            maxPhotoId: 13,
            uploadFinished: true, 
            standardPhotosTexts: null,           
            standardPhotos: [
                {id: 1, type: 0,  fileData: null, uploaded: false, metadata: null, format: "video/webm",required: false, fileName: "video", title: "Video rund um das Fahrzeug, max. 1 Minute", image: null, icon: "/images/icons/aufnahme.png", description: "Video rechts um das Fahrzeug, in gleichbleibendem Abstand", info: ""},            
                {id: 2, type: 0,  fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "fhzgschein_image", title: "Fahrzeugschein", image: null, icon: "/images/icons/Zulassung.png", description: "Fahrzeugschein", info: "wie fotografiere ich den schein"},
                {id: 3, type: 0,  fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "tacho_image", title: "Kilometerstand - bei laufendem Motor", image: null, icon: "/images/icons/tacho.png", description: "Tacho mit gut lesbarem Kilometer Stand", info: "darauf achten dass der blitz aus ist"},
                {id: 4, type: 0,  fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "front_image", title: "Fahrzeug von vorne - Abstand ca. 2,5m", image: null, icon: "/images/icons/Pkw1_front.png", description: "Fahrzeug Front", info: ""},
                {id: 5, type: 0,  fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "rechts_image", title: "Fahrzeug von rechts - Abstand ca. 2 - 3,5m", image: null, icon: "/images/icons/Pkw1_rechteSeite.png", description: "rechte Fahrzeug Seite", info: ""},
                {id: 6, type: 0,  fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "heck_image", title: "Fahrzeug von hinten - Abstand ca. 2,5m", image: null, icon: "/images/icons/Pkw1_back.png", description: "Fahrzeug Heck", info: ""},
                {id: 7, type: 0,  fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "links_image", title: "Fahrzeug von links - Abstand ca. 2 - 3,5m", image: null, icon: "/images/icons/Pkw1_linkeSeite.png", description: "linke Fahrzeug Seite", info: ""},
                {id: 8, type: 0,  fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "fahrzeugreifen_image", title: "Fahrzeug Reifen - Abstand ca. 1,5 - 2m", image: null, icon: "/images/icons/reifen.png", description: "Fahrzeug Reifen", info: ""},                
                {id: 9, type: 2,  fileData: null, uploaded: false, metadata: null, format: "doc",       required: false, fileName: "document1_image", title: "Sonstige Dokumente z.B.: Kostenvoranschlag", image: null, icon: this.documentIcon, description: "", info: ""},
                {id: 10, type: 1,  fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "gesamtschaden_image", title: "Schaden Übersicht", image: null, icon: "/images/icons/detail1.png", description: "", info: ""},
                {id: 11, type: 1, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "detail1_image", title: "Schaden Foto - im Detail 1 Abstand 1 - 2m", image: null, icon: this.damageIcon, description: "", info: ""},
                {id: 12, type: 1, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "detail2_image", title: "Schaden Foto - im Detail 2 Abstand 0,5 - 1m", image: null, icon: this.damageIcon, description: "", info: ""},
                {id: 13, type: 3, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: false, fileName: "bankcardfront_image", title: "Bank Karte Vorder-Seite", image: null, icon: "/images/icons/credit_card_front.png", description: "", info: ""},
                {id: 14, type: 3, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: false, fileName: "bankcardback_image", title: "Bank Karte Rück-Seite", image: null, icon: "/images/icons/credit_card_back.png", description: "", info: ""},                
                {id: 15, type: 4, fileData: null, uploaded: false, metadata: null, format: "doc",  required: false, fileName: "vorschaden1_image", title: "Foto des Schadens oder des Kostenvoranschlags", image: null, icon: this.documentIcon, description: "Hatten Sie an Ihrem Fahrzeug schon einen Vorschaden?", info: ""},                
                {id: 16, type: 2, fileData: null, uploaded: false, metadata: null, format: "doc",  required: photoRequired, fileName: "wildunfallbescheinigung_image", title: "Bescheinigung des Wildunfalles", image: null, icon: "/images/icons/wildunfallbescheinigung.png", description: "", info: ""},                
            ],
            isUploading:[false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false, false, false],            
            // carTypes: [
            //     {id :1, type: "kombi", masks: [
            //         {fid: 0, mask: "fzschein.png"},
            //         {fid: 3, mask: "numberplate.png"},
            //         {fid: 4, mask: "silhouette-front.png"},
            //         {fid: 5, mask: "srect10.png"},
            //         {fid: 7, mask: "srect10.png"},
            //     ]},
            //     {id: 2, type: "suv", masks: [
            //         {fid: 0, mask: "fzschein.png"},
            //         {fid: 3, mask: "numberplate.png"},
            //         {fid: 4, mask: "silhouette-front.png"},
            //         {fid: 5, mask: "srect10.png"},
            //         {fid: 7, mask: "srect10.png"},
            //     ]},
            //     {id: 3, type: "compact", masks: [
            //         {fid: 0, mask: "fzschein.png"},
            //         {fid: 3, mask: "numberplate.png"},
            //         {fid: 4, mask: "silhouette-front.png"},
            //         {fid: 5, mask: "srect10.png"},
            //         {fid: 7, mask: "srect10.png"},
            //     ]},
            //     {id: 4, type: "limo", masks: [
            //         {fid: 0, mask: "fzschein.png"},
            //         {fid: 3, mask: "numberplate.png"},
            //         {fid: 4, mask: "silhouette-front.png"},
            //         {fid: 5, mask: "srect10.png"},
            //         {fid: 7, mask: "srect10.png"},
            //     ]}   
            // ]
        };        
    }

    onUploading = (photoId)  => {
        let uploading = this.state.uploading;
        let isUploading = this.state.isUploading;
        uploading = uploading + 1;
        isUploading[photoId] = true;

        console.log("onUploading: ",isUploading[photoId]);
        this.setState({
            uploading: uploading,
            isUploading: isUploading
        });
    };
    
    checkIfUploading = () => {
        let uploadInfo = this.state.isUploading;
        for (let i=1; i <=uploadInfo.length; i++) {            
            if (uploadInfo[i]) {                
                return true;
            }
        }        
        return false;
    }

    onSetConnectionParams = () => {        
        
        let systemID = this.state.systemID;
        if (systemID === "dev") {
            this.setState({
                dataUrl: "https://carpic-dev.svpro.de/backend/getdata.php?key=",
                uploadTarget: "https://carpic-dev.svpro.de/backend/",
            });
        } else if (systemID === "prod") {
            this.setState({
                dataUrl: "https://carpic.svpro.de/backend.new/getdata.php?key=",
                uploadTarget: "https://carpic.svpro.de/backend.new/",
                //uploadTarget: "https://carpic.svpro.de/backend.new/",
            });
        } else if (systemID === "test") {
            this.setState ({
                dataUrl: "https://carpic-test.svpro.de/backend/getdata.php?key=",
                uploadTarget: "https://carpic-test.svpro.de/backend/",
            });
        }        
        console.log("onSetConnectionParams for systemID: "+systemID);
        console.log("onSetConnectionParams DATA: "+this.state.dataUrl);
        console.log("onSetConnectionParams DATA: "+this.state.uploadTarget);
    }

    onTransferFinished = (finished) => {
        this.setState({
            transferFinished: finished
        })
    }

    
    onBuildPhotoList = (keyList, key) => {
        const neededPhotos =([

        ]);
        let id=1;
        
        let damagePhotos = 0;
        let docPhotos = 0;
        let chargeType = "-1";

        let picturesComplete = true;    
        let standardPhotosTexts = [];

        if (!keyList.includes("Bankkarte Vorderseite")) {
            keyList.push("Bankkarte Vorderseite");
        }    
        if (!keyList.includes("Bankkarte Rückseite")) {
            keyList.push("Bankkarte Rückseite");
        }    
        if (!keyList.includes("Vorschaden 1")) {
            keyList.push("Vorschaden 1");
        }    
        if (!keyList.includes("Vorschaden 2")) {
            keyList.push("Vorschaden 2");
        }    

        const photoRequired = true;

        const photoList = ([
            {key: "Video",                  id: 0, type: 0, fileData: null, uploaded: false, metadata: null, format: "video/webm", required: false,  fileName: "video", title: "Video rund um das Fahrzeug, max. 1 Minute", image: null, icon: "/images/icons/aufnahme.png", description: "Video rechts um das Fahrzeug, in gleichbleibendem Abstand", info: "", "skipbtn": false},            
            {key: "Fahrzeugschein",         id: 0, type: 0, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "fhzgschein_image", title: "Fahrzeugschein", image: null, icon: "/images/icons/Zulassung.png", description: "Fahrzeugschein", info: "wie fotografiere ich den schein", "skipbtn": false},
            {key: "Kilometerstand",         id: 0, type: 0, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "tacho_image", title: "Kilometerstand - bei laufendem Motor", image: null, icon: "/images/icons/tacho.png", description: "Tacho mit gut lesbarem Kilometer Stand", info: "darauf achten dass der blitz aus ist", "skipbtn": false},
            {key: "Fahrzeug von vorne",     id: 0, type: 0, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "front_image", title: "Fahrzeug von vorne - Abstand ca. 2,5m", image: null, icon: "/images/icons/Pkw1_front.png", description: "Fahrzeug Front", info: "", "skipbtn": false},
            {key: "Fahrzeug von rechts",    id: 0, type: 0, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "rechts_image", title: "Fahrzeug von rechts - Abstand ca. 2 - 3,5m", image: null, icon: "/images/icons/Pkw1_rechteSeite.png", description: "rechte Fahrzeug Seite", info: "", "skipbtn": false},
            {key: "Fahrzeug von hinten",    id: 0, type: 0, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "heck_image", title: "Fahrzeug von hinten - Abstand ca. 2,5m", image: null, icon: "/images/icons/Pkw1_back.png", description: "Fahrzeug Heck", info: "", "skipbtn": false},
            {key: "Fahrzeug von links",     id: 0, type: 0, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "links_image", title: "Fahrzeug von links - Abstand ca. 2 - 3,5m", image: null, icon: "/images/icons/Pkw1_linkeSeite.png", description: "linke Fahrzeug Seite", info: "", "skipbtn": false},
            {key: "Fahrzeug Reifen",        id: 0, type: 0, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "fahrzeugreifen_image", title: "Fahrzeug Reifen - Abstand ca. 1,5 - 2m", image: null, icon: "/images/icons/reifen.png", description: "Fahrzeug Reifen", info: "", "skipbtn": false},
            {key: "Dokument 1",             id: 0, type: 2, fileData: null, uploaded: false, metadata: null, format: "doc",       required: false, fileName: "document1_image", title: "Sonstige Dokumente z.B.: Kostenvoranschlag", image: null, icon: this.documentIcon, description: "", info: "", "skipbtn": true},
            {key: "Dokument 2",             id: 0, type: 2, fileData: null, uploaded: false, metadata: null, format: "doc",       required: false, fileName: "document1_image", title: "Sonstige Dokumente z.B.: Kostenvoranschlag", image: null, icon: this.documentIcon, description: "", info: "", "skipbtn": false},
            {key: "Schaden Übersicht",      id: 0, type: 1, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "gesamtschaden_image", title: "Schaden Übersicht", image: null, icon: "/images/icons/detail1.png", description: "", info: "", "skipbtn": false},
            {key: "Schaden Detail 1",       id: 0, type: 1, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "detail1_image", title: "Schaden Foto - im Detail 1 Abstand 1 - 2m", image: null, icon: this.damageIcon, description: "", info: "", "skipbtn": false},
            {key: "Schaden Detail 2",       id: 0, type: 1, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "detail2_image", title: "Schaden Foto - im Detail 2 Abstand 0,5 - 1m", image: null, icon: this.damageIcon, description: "", info: "", "skipbtn": false},
            {key: "Vorschaden 1",           id: 0, type: 4, fileData: null, uploaded: false, metadata: null, format: "doc",       required: false, fileName: "vorschaden1_image", title: "Foto des Schadens oder des Kostenvoranschlags", image: null, icon: this.documentIcon, description: "Hatten Sie an Ihrem Fahrzeug schon einen Vorschaden?", info: "", "skipbtn": false},                            
            {key: "Vorschaden 2",           id: 0, type: 4, fileData: null, uploaded: false, metadata: null, format: "doc",       required: false, fileName: "vorschaden2_image", title: "Foto des Schadens oder des Kostenvoranschlags", image: null, icon: this.documentIcon, description: "", info: "", "skipbtn": false},                            
            {key: "Wildunfallbescheinigung",id: 0, type: 2, fileData: null, uploaded: false, metadata: null, format: "doc",       required: true, fileName: "wildunfallbescheinigung_image", title: "Foto der Wildunfallbescheinigung", image: null, icon: "/images/icons/wildunfallbescheinigung.png", description: "Wildunfallbescheinigung", info: "", "skipbtn": false},                            
            {key: "Bankkarte Vorderseite",  id: 0, type: 3, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: false, fileName: "bankcardfront_image", title: "Bank Karte Vorder-Seite", image: null, icon: "/images/icons/credit_card_front.png", description: "", info: "", "skipbtn": false},
            {key: "Bankkarte Rückseite",    id: 0, type: 3, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: false, fileName: "bankcardback_image", title: "Bank Karte Rück-Seite", image: null, icon: "/images/icons/credit_card_back.png", description: "", info: "", "skipbtn": false},                            
        ]);
        let isUploading = [];

        photoList.map((photo, index) => {
            if (keyList.includes(photo.key)) {
                photo.id = id;
                photo.required = photoRequired;
                id = id + 1;

                standardPhotosTexts.push(photo.key);

                //check if we already upoaded the photo
                let uploadedString = localStorage.getItem(key+"_up_"+photo.id);
                let uploaded = true;
                if (photo.key === "Bankkarte Vorderseite" || photo.key === "Bankkarte Rückseite") {
                    chargeType = "-2";
                    photo.required = false;
                }


                if (photo.key === "Vorschaden 1" || photo.key === "Vorschaden 2") {                    
                    photo.required = false;
                }
                if (uploadedString === null || uploadedString === "undefined") {
                    uploaded = false;
                    if (photo.required) {
                        console.log(photo.key+" is required");
                        picturesComplete = false;
                    } else {
                        console.log(photo.key+" is NOT required");
                    }
                }
                const metaJson = localStorage.getItem(key+"_meta_"+photo.id);
                let metadata = null;       
                if (metaJson !== null && typeof metaJson === "string") {
                    try {
                        metadata = JSON.parse(metaJson);       
                    } catch(e) {
                        console.log("onBuildPhotoList(): the string is not a json string");
                        metadata = null;
                    }
                }
    
                photo.uploaded = uploaded;
                photo.metadata = metadata;

                neededPhotos.push(photo);  
                isUploading[photo.id] = false;              
                if (photo.type === 2) {
                    docPhotos = docPhotos + 1; 
                } else if (photo.type === 1) {
                    damagePhotos = damagePhotos + 1; 
                }
            }
            return true;
        });        
        console.log("Complete: ", picturesComplete);
        this.setState({
            uploadFinished: picturesComplete,
            modalVisible: picturesComplete,
            standardPhotosTexts: standardPhotosTexts,
            key: key,
            standardPhotos: neededPhotos,
            maxPhotoId: id - 1,
            damageDetailPhotos: damagePhotos,
            documents: docPhotos,
            chargeType: chargeType,
            moreButtons: false,
            isUploading: isUploading
        })

    }
    
    onAcceptDsgvo = (accepted) => {
        this.setState({dsgvoAccepted: accepted})
    }                

    checkUploadStatus = (chargeType) => {
        let uploadFinished = this.state.uploadFinished;

        if ((chargeType === 1 || chargeType === "1") && (this.state.chargeType === 1 || this.state.chargeType === "1")) {
            this.state.standardPhotos.forEach(photo => {            
                if (photo.type === 3) {
                    if (photo.required && !photo.uploaded) {
                        uploadFinished = false;                        
                    }
                }
            });
        }
        this.setState({
            uploadFinished: uploadFinished,
            modalVisible: uploadFinished
        });
    }

    onVideoChat = () => {
        this.setState({
            jitsiStarted: !this.state.jitsiStarted
        })            
    }

    onChangeBankData = (field, value) => {
        /* firstname: "",
        familyname: "",                
        iban: "" */
        let bankData = this.state.bankData;
        if (field === "firstname") {
            bankData.firstname = value;
        } else if (field === "familyname") {
            bankData.familyname = value;
        } else if (field === "iban") {
            bankData.iban = value;
        }
        this.setState({
            bankData: bankData
        });
    }

    onChangeVorschaden = (field, vorSchaden) => {                
        if (field === "hatvorschaden_option") {
            let photos = this.state.standardPhotos;
            //type=4 -> title
            let newTitle = "Foto des Schadens oder des Kostenvoranschlags";
            if (vorSchaden === 0 || vorSchaden === "0") {
                newTitle = "Foto des Schadens oder der Rechnung";
            }
            photos.forEach(photo => {            
                if (photo.type === 4) {
                    photo.title = newTitle;
                }
            });                                    

            this.setState({
                vorschadenDetail: vorSchaden,
                vorschadenAmount: 0.0,
                standardPhotos: photos
            });
        } else if (field === "hatvorschaden") {
            this.setState({
                vorSchaden: vorSchaden,
                vorschadenDetail: -1,
                vorschadenAmount: 0.0
            });                      
        } else if (field === "vorschadenamount") {
            this.setState({
                vorschadenAmount: vorSchaden                            
            });
        }  
    };

    onChangeData = (field, value) => {
        console.log("onChangeData (Carpic) "+field+" : "+value);
        if(field === "customer_mail") {        
            localStorage.setItem(this.state.key+"_customer_mail", value);    
            this.setState({
                customer_mail: value
            });
        } else {
            this.setState({
                choose_copart: value
            });
        }        
    };
    
    onChangechargeType = (field, type) => {        

        //cred card images: type=3
        const standardPhotos = [...this.state.standardPhotos];
        let newStandardPhotos = [];
        let uploadFinished = true;

        let required = false;
        if (type === 1 || type === "1") {
            required = true;
        }

        standardPhotos.forEach(photo => {    
            
            
            if (photo.type === 3) {
                photo.required = required;
            }
            if (photo.required && photo.uploaded === false) {
                uploadFinished = false;                
            }          
            newStandardPhotos.push(photo); 
        });

        
        this.setState({
            chargeType: type, 
            uploadFinished: uploadFinished,   
            standardPhotos: newStandardPhotos
        })
    }

    //FQAIUFJK DEV -> 2021
    //GAKBHIMQ PROD -> timedout
    

    addDamageDetail = () => {
        if (this.state.damageDetailPhotos < this.state.damageDetailPhotoMax) {
            const standardPhotos = [...this.state.standardPhotos];
            let newId = this.state.maxPhotoId +1;
            let newDamagePhotos = this.state.damageDetailPhotos + 1;
            let isUploading = this.state.isUploading;
            const newDamagePhoto = {
                id: newId, 
                type: 1, 
                fileData: null, 
                format: "img/jpeg", 
                required: false, 
                title: `Detail ${newDamagePhotos}`, 
                fileName: `detail${newDamagePhotos}_image`,
                image: null, 
                url: null, 
                metadata: null,
                icon: this.damageIcon, 
                description: "", 
                info: ""        
            };

            let newStandardPhotos = [];
            
            standardPhotos.forEach(photo => {            
                newStandardPhotos.push(photo); 
            });
            newStandardPhotos.push(newDamagePhoto);
            isUploading[newId] = false;
            this.setState ({
                maxPhotoId: newId,
                damageDetailPhotos: newDamagePhotos,
                standardPhotos: newStandardPhotos,
                isUploading: isUploading
            });
            if(newDamagePhotos >= this.state.damageDetailPhotoMax) {
                this.setState({
                    damageDetailAddButton: false
                })
            }            
        }
    }

    addDocuments = () => {
        if (this.state.documents < this.state.documentMax) {
            const standardPhotos = [...this.state.standardPhotos];
            let newId = this.state.maxPhotoId +1;
            let curDocId = this.state.documents;
            let isUploading = this.state.isUploading;
            const newDocumentPhoto = {
                id: newId, 
                type: 2, 
                fileData: null, 
                format: "doc", 
                required: false, 
                title: `Dokument ${this.state.documents}`, 
                fileName: `document${curDocId+1}_image`,
                image: null, 
                url: null, 
                icon: this.documentIcon, 
                metadata: null,
                description: "", 
                info: ""        
            };
            let newDocuments = curDocId + 1;

            let newStandardPhotos = [];
            isUploading[newId] = false;
            
            standardPhotos.forEach(photo => {            
                newStandardPhotos.push(photo); 
            });
            newStandardPhotos.push(newDocumentPhoto);

            this.setState ({
                maxPhotoId: newId,
                documents: newDocuments,
                standardPhotos: newStandardPhotos,
                isUploading: isUploading
            });
            if(newDocuments >= this.state.documentMax) {
                this.setState({
                    documentAddButton: false
                })
            }
        }        
    }    
    
    async componentDidMount() {   
    
        let queryString = new URLSearchParams(window.location.search);
        let key= queryString.get("key");
        
        this.onSetConnectionParams();

        //try to get previously taken photos from local storage
        const standardPhotos = [...this.state.standardPhotos];
        let newStandardPhotos = [];

        //rset localStorage

        //NZWZTXGP
    
            
        const callUrl = this.state.dataUrl+key;      
        const formData = new FormData();
        formData.append("key", key);
        formData.append("systemID", this.state.systemID);
        Axios.post(callUrl, formData, null).then(res => {
            const contractData = res.data.data;            
            if (contractData === "Invalid key." || contractData === null) {

                this.setState({
                    contractData: null,
                    dataLoaded: true,
                    errorMessage: res.data.message
                })
            } else {
                const maxPhotoSize = res.data.config.max_photo_size;
                const maxVideoSize = res.data.config.max_video_size;
                
                let numberPlate = localStorage.getItem(key+"_fzg_kennzeichen");            
                if (numberPlate && contractData.fzg_kennzeichen.length === 0) {
                    contractData.fzg_kennzeichen = numberPlate;
                }
                let internalNumber = localStorage.getItem(key+"_v_schadennr");            
                if (internalNumber && contractData.v_schadennr.length === 0) {
                    contractData.v_schadennr = internalNumber;
                }

                contractData.gutachten_nr_formatiert = contractData.svpro_nr;
                
                

                this.setState({
                    key: key,
                    fileMaxSizeMultiPhoto: maxPhotoSize,
                    fileMaxSizeMultiVideo: maxVideoSize,
                    vu_schadennummer: contractData.vu_schadennummer,
                    svp: contractData.svp,
                    auszahlung_wbw_netto: contractData.auszahlung_wbw_netto,
                    contractData: contractData,
                    injuranceName: contractData.vu_name,
                    injurenceLogo: contractData.vu_logo,
                    haftpflicht: contractData.haftpflicht,
                    schadenausweitung: contractData.schadenausweitung,
                    dataLoaded: true,
                    appInitialized: true,                    
                    customer_mail: contractData.vn_email,
                    fzg_aufbauart: contractData.fzg_aufbauart,
                    fzg_fahrzeugart: contractData.fzg_fahrzeugart,
                });
                if (contractData.ansichten !== "*" && contractData.ansichten[0] !== "*") {
                    this.onBuildPhotoList(contractData.ansichten, key);
                } else {
                    let picturesComplete = true;       
                    standardPhotos.forEach(photo => {            
                        let uploadedString = localStorage.getItem(key+"_up_"+photo.id);       
                        let uploaded = true;
                        if (uploadedString === null || uploadedString === "undefined") {
                            uploaded = false;
                            if (photo.required) {
                                picturesComplete = false;
                            }
                        }
                        
                        const metaJson = localStorage.getItem(key+"_meta_"+photo.id);
                        let metadata = null;       
                        if (metaJson !== null && typeof metaJson === "string") {
                            try {
                                metadata = JSON.parse(metaJson);       
                            } catch(e) {
                                console.log("ComponentDidMount(): the string is not a json string");
                                metadata = null;
                            }
                        }
            
                        photo.uploaded = uploaded;
                        photo.metadata = metadata;                       
                        newStandardPhotos.push(photo); 
                    });
            
                    this.setState({
                        standardPhotos: newStandardPhotos,
                        uploadFinished: picturesComplete,
                        modalVisible: picturesComplete,
                        key: key
                    }); 
                }
            }
        }).catch(
            (error) => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.header);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log("Error", error.message);
                }
                this.setState({
                    networkError: true,
                    errorMessage: error.message
                })
            }
        );    
    }

    sendAssignementData = (data) => {      
        const curState = this.state;
        let bankData = null;
        if (curState.chargeType === 1 || curState.chargeType === "1") {
            let firstName  = document.getElementById("firstname").value;
            let familyName = document.getElementById("familyname").value;;
            let iban = document.getElementById("iban").value;

            bankData = {
                firstname: firstName,
                familyname: familyName,                
                iban: iban 
            };
        } else {
            bankData = {
                firstname: "",
                familyname: "",                
                iban: "" 
            };
        }

        this.setState({
            bankData: bankData,
            view: "upload"
        });
    }

    clearAssignementData = () => {
        //1. check if the user really wants to do that
        //2. remove photos from server
        //3. remove everything for the given KEY from the local storage
        const curState = this.state;
        const standardPhotos = [...this.state.standardPhotos];
        let newStandardPhotos = [];

        let removeOK = true;

        const svpNumber = curState.svp;
        if (removeOK) {

            const body = new FormData()
            body.append("action", "cancel");            
            body.append("key", curState.key);
            body.append("svp", svpNumber);

            const callUrl = curState.uploadTarget+"upload.php";

            Axios.post(callUrl, body).then(res => {
                const data = res.data;
                if (data.code === 200) {                    
                    standardPhotos.forEach(photo => {  
                        if (photo.uploaded) {               
                            localStorage.removeItem(curState.key+"_up_"+photo.id);                   
                            localStorage.removeItem(curState.key+"_meta_"+photo.id);                                        
                            console.log("REMOVE localStorage entry for ", curState.key, photo.id, svpNumber);                                                                         
                            photo.uploaded = false;
                            photo.metadata = null;
                            photo.fileData = null;
                            photo.image = null;
                            newStandardPhotos.push(photo); 
                        }
                    });

                    this.setState({
                        standardPhotos: newStandardPhotos,
                        uploadFinished: false,
                        modalVisible: false,
                        jobCanceled: true
                    });   
                }
            }).catch(
                (error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.header);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }                           
                }
            );           
        }

    }

    clearAssignementData_OLD = () => {
        //1. check if the user really wants to do that
        //2. remove photos from server
        //3. remove everything for the given KEY from the local storage
        const curState = this.state;
        const standardPhotos = [...this.state.standardPhotos];
        let newStandardPhotos = [];

        let removeOK = true;

        const svpNumber = curState.svp;
        if (removeOK) {
            standardPhotos.forEach(photo => {  
                if (photo.uploaded) {                                                            
                    let format = "";
                    if (photo.metadata != null && photo.metadata.type != null) {
                        format = photo.metadata.type;
                    }

                    const body = new FormData()
                    body.append("action", "del");
                    body.append("subaction", "cancel");
                    body.append("id", photo.id);
                    body.append("ftype", format);
                    body.append("key", curState.key);
                    body.append("svp", svpNumber);
                    body.append("filename", photo.fileName);

                    const callUrl = curState.uploadTarget+"upload.php";

                    Axios.post(callUrl, body).then(res => {
                        const data = res.data;
                        if (data.code === 200) {
                            localStorage.removeItem(data.key+"_up_"+data.pid);                   
                            localStorage.removeItem(data.key+"_meta_"+data.pid);                                        
                            console.log("REMOVE localStorage entry for ", data.key, data.pid, data.svp);
                        }
                    }).catch(
                        (error) => {
                            if (error.response) {
                                console.log(error.response.data);
                                console.log(error.response.status);
                                console.log(error.response.header);
                            } else if (error.request) {
                                console.log(error.request);
                            } else {
                                console.log("Error", error.message);
                            }                           
                        }
                    );

                    console.log("clearAssignementData(): DELETED: ", svpNumber, photo.id);        
                } else {
                    console.log("clearAssignementData() not deleted: ", svpNumber, photo.id)
                }
                photo.uploaded = false;
                photo.metadata = null;
                photo.fileData = null;
                photo.image = null;
                newStandardPhotos.push(photo); 
            });
            this.setState({
                standardPhotos: newStandardPhotos,
                uploadFinished: false,
                modalVisible: false
            });     
            console.log("clearAssignementData(): remove all already transfered images and clear the localStorage");
        }

    }

    changeView = (data) => {
        this.setState({
            view: data.view,
            currentPhoto: data});        
    }
   
    changePhotoSize = (sizeId) => {
        const photoSizes = [
            {name: "Lowres", width: 480, height: 360},
            {name: "Medres", width: 640, height: 480},
            {name: "HD", width: 1280, height: 720},
            {name: "Full HD", width: 1920, height: 1080},
        ];
        this.setState({photoSize: photoSizes[sizeId]});
    }


    onChangeContractData = (contractElemet) => {        
        const _contractData = this.state.contractData;
        switch(contractElemet.name) {
            case "carType":
                this.setState({carType: contractElemet.value});
                localStorage.setItem(this.state.key+"_cartype", contractElemet.value);
            break;
            case "gutachten_nr_formatiert":
                _contractData.gutachten_nr_formatiert = contractElemet.value;
                this.setState({contractData: _contractData});
                localStorage.setItem(this.state.key+"_"+contractElemet.name, contractElemet.value);
            break;
            case "fzg_kennzeichen":                
                _contractData.fzg_kennzeichen = contractElemet.value;
                this.setState({contractData: _contractData});
                localStorage.setItem(this.state.key+"_"+contractElemet.name, contractElemet.value);
            break;
            case "v_schadennr":                                
                _contractData.v_schadennr = contractElemet.value;
                this.setState({contractData: _contractData});
                localStorage.setItem(this.state.key+"_"+contractElemet.name, contractElemet.value);
            break;
            default:
            break;
        }
        this.setState({carType: contractElemet.value});
        localStorage.setItem(this.state.key+"_cartype", contractElemet.value);
    }

    takePartPhotoHandler = (partName, fileName, state="default") => {
        console.log("photo taken for Part: "+partName);
        console.log("photo taken for Part: "+fileName);
        const curPartList = this.state.partList;
        curPartList[partName].state = state;
        curPartList[partName].filename = "/../pictures/"+this.state.svp+"/"+this.state.svp+"_"+partName+".schadenbild.jpeg";
        this.setState({
            partList: curPartList
        });
    };

    changePartState = (partName, state) => {
        console.log(partName+" State: "+state);
        const curPartList = this.state.partList;
        if (curPartList[partName].state !== "partTaken") {
            curPartList[partName].state = state;
            this.setState({
                partList: curPartList
            });
        }
    }

    onSkipPhoto = (photoID) => {
        if (photoID >= 0) {
            const standardPhotos = this.state.standardPhotos;
            let newPhotos = [];
            standardPhotos.forEach(photo => {
                if (photo.id === photoID) {
                    photo.required = false;
                }
                newPhotos.push(photo);
            });
            this.setState({                                
                standardPhotos: newPhotos
            });
        }
    };

    takePhotoHandler = (photoData) => {                
        const curState = this.state;
        this.setState({view: photoData.view});
        const photoID = photoData.id;
        let uploadingNew = this.state.uploading;        
        if (photoID >= 0) {
            this.onError(null,"");
            let picturesComplete = true;
            const standardPhotos = this.state.standardPhotos;
            let newPhotos = [];
            let isUploading = this.state.isUploading;
            standardPhotos.forEach(photo => {
                if (photo.id === photoID) {
                    photo.fileData = photoData.fileData;
                    photo.image = photoData.image; 
                    photo.url = photoData.url; 
                    photo.uploaded = photoData.uploaded;
                    photo.metadata = photoData.metadata;
                    let format = "";
                    if (photoData.metadata != null && photoData.metadata.type != null) {
                        format = photoData.metadata.type;
                    }
                    photo.format = format;
                    if (photoData.fileData && photoData.metadata && photoData.metadata) {
                        photo.format = photoData.metadata.type;
                    }
                    try {
                        localStorage.setItem(curState.key+"_up_"+photo.id, photoData.uploaded);       
                        localStorage.setItem(curState.key+"_meta_"+photo.id, JSON.stringify(photoData.metadata));                                                                             
                    } catch (e) {
                        console.log("Local Storage error: ", e);
                        return false;
                    }
                }
                if (!photo.uploaded && photo.required) {
                    picturesComplete = false;
                }
                newPhotos.push(photo);
                isUploading[photoID] = false;
                uploadingNew = uploadingNew -1;
                if (uploadingNew < 0) {
                    uploadingNew = 0;
                }
            });
            this.setState({                
                uploadFinished: picturesComplete,
                modalVisible: picturesComplete,
                generalError: false,
                errorMessage: null,
                standardPhotos: newPhotos,
                uploading: uploadingNew,
                isUploading: isUploading
            })
        }        
    }
    
    openPhoto = (photoData) => {
        console.log("Carpic OpenPhoto:",photoData);
    }
    
    deletePhoto = (photoData) => {
        const curState = this.state;
        const svpNumber = curState.svp;//contractData.gutachten_nr_formatiert;
        confirmAlert({
            title: "Bild wirklich löschen",
            message: "Sind Sie sicher, dass Sie das Bild für '"+photoData.title+"' löschen wollen?",
            buttons: [                
                {
                    label: 'Nein',
                    onClick: () => console.log("do not delete")
                }, {
                label: 'Ja',
                onClick: () => {
                    const standardPhotos = [...this.state.standardPhotos];
                    let newStandardPhotos = [];
                    standardPhotos.forEach(photo => {
                        if (photo.id === photoData.id) {     
                            let format = "";
                            if (photo.metadata != null && photo.metadata.type != null) {
                                format = photo.metadata.type;
                            }
                       
                            const body = new FormData()
                            body.append("action", "del");
                            body.append("id", photo.id);
                            body.append("ftype", format);
                            body.append("key", this.state.key);
                            body.append("svp", svpNumber);
                            body.append("filename", photo.fileName);
        
                            const callUrl = this.state.uploadTarget;
        
                            Axios.post(callUrl+"upload.php", body).then(res => {
                                const data = res.data;        
                                if (data.code === 200) {
                                    localStorage.removeItem(data.key+"_up_"+data.pid);                   
                                    localStorage.removeItem(data.key+"_meta_"+data.pid);                                    

                                    if (photo.required) {
                                        this.setState({uploadFinished: false});
                                    }
                                }
                            }).catch(
                                (error) => {
                                    if (error.response) {
                                        console.log(error.response.data);
                                        console.log(error.response.status);
                                        console.log(error.response.header);
                                    } else if (error.request) {
                                        console.log(error.request);
                                    } else {
                                        console.log("Error", error.message);
                                    }                           
                                }
                            );
                            photo.uploaded = false;
                            photo.metadata = null;
                            photo.fileData = null;
                            photo.image = null;                                       
                        }
                        newStandardPhotos.push(photo);
                    });

                    this.setState({
                        standardPhotos: newStandardPhotos
                    })
                }
              }
            ]
          });
    }

  onError = (error, errorMessage) => {      
      //1 fileupload error
      this.setState({
            generalError: error,
            errorMessage: errorMessage
        });
        if (error === "1") {
            //spinner
        }
  }

    showPreview = (photoData) => {
        this.setState({
            view: "preview",
            currentPhoto: photoData});
    }
  
    uploadUrl = "";

    setModalVisible = () => {
        console.log("Caspic.jsx::setModalVisible");
        this.setState({
            modalVisible: false});
        //move to ID controlsbox

    }
    
    render() {
        let view = (
            <div>
                <Layout 
                    appInitialized={this.state.appInitialized}
                    systemID={this.state.systemID}
                    injuranceName={this.state.injuranceName}
                    injurenceLogo={this.state.injurenceLogo}
                    haftpflicht={this.state.haftpflicht}   
                    onError={this.onError}   
                    generalError={this.state.generalError}              
                    errorMessage={this.state.errorMessage}                
                    error={this.state.networkError}
                    assKey={this.state.key}
                    svp={this.state.svp}
                    uploadTarget={this.state.uploadTarget}
                    contractData={this.state.contractData} 
                    standardPhotosTexts={this.state.standardPhotosTexts}
                    standardPhotos={this.state.standardPhotos}                 
                    damageDetailPhotos={this.state.damageDetailPhotos}
                    documents={this.state.documents}
                    takePhotoHandler={this.takePhotoHandler}
                    onSkipPhoto={this.onSkipPhoto}
                    changeView={this.changeView}
                    openPhoto={this.openPhoto}
                    fileMaxSizeMultiPhoto={this.state.fileMaxSizeMultiPhoto}
                    fileMaxSizeMultiVideo={this.state.fileMaxSizeMultiVideo}                    
                    
                    modalVisible={this.state.modalVisible}
                    setModalVisible={this.setModalVisible}
                    showPreview={this.showPreview}
                    sendAssignementData={this.sendAssignementData}
                    clearAssignementData={this.clearAssignementData}
                    deletePhoto={this.deletePhoto}
                    onChangeContractData={this.onChangeContractData}
                    addDamageDetail={this.addDamageDetail}
                    moreButtons={this.state.moreButtons}
                    damageDetailAddButton={this.state.damageDetailAddButton}
                    addDocuments={this.addDocuments}
                    documentAddButton={this.state.documentAddButton}
                    dsgvoAccepted={this.state.dsgvoAccepted}
                    onAcceptDsgvo={this.onAcceptDsgvo}
                    vorSchaden={this.state.vorSchaden}
                    
                    vorschadenDetail={this.state.vorschadenDetail}
                    vorschadenAmount={this.state.vorschadenAmount}
                    onChangechargeType={this.onChangechargeType}
                    onChangeVorschaden={this.onChangeVorschaden}                                         
                    
                    onChangeBankData={this.onChangeBankData}
                    chargeType={this.state.chargeType}
                    bankData={this.state.bankData}
                    uploadFinished={this.state.uploadFinished}        
                    jobCanceled={this.state.jobCanceled}        
                    transferFinished={this.state.transferFinished}
                    onTransferFinished={this.onTransferFinished}
                    isUploading={this.state.uploading}
                    onUploading={this.onUploading}
                    checkIfUploading={this.checkIfUploading}
                    auszahlung_wbw_netto={this.state.auszahlung_wbw_netto}
                    choose_copart={this.state.choose_copart}
                    customer_phone={this.state.customer_phone} 
                    customer_mail={this.state.customer_mail}    
                    onChangeData={this.onChangeData}
                    
                    onVideoChat={this.onVideoChat}
                    jitsiStarted={this.state.jitsiStarted}
                    
                    partList={this.state.partList}
                    takePartPhotoHandler={this.takePartPhotoHandler}
                    changePartState={this.changePartState}
                    />                   
{/*                  <Jutsu 
                    domain="meet.jit.si"
                    roomName="svprotest" 
                    userName="Client"
                    />  */}
            </div>
        );
        if (this.state.view === "cam") {

        } else if (this.state.view === "preview") {
            view = <PhotoPreview 
                        photoData={this.state.currentPhoto}                
                        changeView={this.changeView}
                    />
        }  else if (this.state.view === "upload") {
            view = <FileUploader 
                        systemID={this.state.systemID}
                        svp={this.state.svp}
                        code={this.state.key}
                        contractData={this.state.contractData}
                        photoList={this.state.standardPhotos}
                        changeView={this.changeView}
                        uploadTarget={this.state.uploadTarget}
                        uploadFinished={this.state.uploadFinished}
                        bankData={this.state.bankData}
                        chargeType={this.state.chargeType}
                        vorSchaden={this.state.vorSchaden}                    
                        vorschadenDetail={this.state.vorschadenDetail}
                        vorschadenAmount={this.state.vorschadenAmount}
                        choose_copart={this.state.choose_copart}
                        customer_mail={this.state.customer_mail}    
                        customer_phone={this.state.customer_phone}    
                        /* onDataComplete={this.state.dataComplete} */
                    />
        }

        if (this.state.dataLoaded === false) {
            view = (
                <div className="container">                
                    <div className="row">
                        <div className="col-md-5"></div> 
                        <div className="col-md-2">
                            <Spinner animation="border" variant="warning" />
                        </div> 
                        <div className="col-md-5"></div> 
                    </div>        
                </div>
            )
        }

        if (this.state.dataReceived === false || this.state.contractData === null) {
            let message = "Konnte die benötigten Daten nicht vom Server laden";
            if (this.state.contractData === null) {                
                message = "Es konnten keine Daten für den benutzten Key gefunden werden. Es ist möglich, dass der Key bereits abgelaufen ist."
                if (this.state.errorMessage.length > 0) {
                    message = this.state.errorMessage;
                }
            }
            view = (
                <MessageHeader 
                    message={message}
                    messageClass="alert-warning"
                />                    
            )
        }    

        return (
            
            <div className="container">                                                                
                {view}                
            </div>
        );
    }
}

export default Carpic;
