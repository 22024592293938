import React, {Suspense, useState} from "react";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { useLoader } from "@react-three/fiber";
import * as THREE from "three";
import state from "./state/state";
import { Html } from "@react-three/drei/web";

import PartColors from "./PartColors";

import "./Model.css"

const Model = props => {

    const textureNames = [];
    const textureMap = [];
    Object.keys(props.partList).map((key, index) => {
        const map = props.partList[key].map != null && props.partList[key].map.length > 5 ? props.partList[key].map: false;
        if (map) {
            textureNames.push(key);
            textureMap.push(map);
        }

    });


    const setDefaultColors = (model) => {
        Object.keys(props.partList).map((key, index) => {
            model.children.forEach(partMesh => {
                //images/krug_logo_transparent.png
                if (key === partMesh.name) {
                    
                    let putTexture = false;
                    if (props.partList[key].map && props.partList[key].map.length > 5) {
                        putTexture = true;
                    }

                    let colorKey = props.partList[key].state === "default" ? props.partList[key].color : props.partList[key].state;

                    switch(props.partList[key].material) {
                        case "m":                            
                            partMesh.material = new THREE.MeshStandardMaterial( {
                                color: PartColors[colorKey],
                                metalness: 0.8,                                
                                roughness: 0.1                                
                            });
                        break;
                        case "g":
                            partMesh.material = new THREE.MeshBasicMaterial( { color: PartColors[colorKey] } );                                            
                        break;
                        case "b":
                            partMesh.material = new THREE.MeshStandardMaterial( {
                                color: PartColors[colorKey],
                                emissive: PartColors[colorKey],
                                emissiveIntensity: 5
                            });                            
                        break;
                        case "r":
                            partMesh.material = new THREE.MeshStandardMaterial( {
                                color: PartColors[colorKey],                                                                
                                roughness: 0.9
                            });    
                        break;
                        case "p":
                            partMesh.material = new THREE.MeshBasicMaterial( { color: PartColors[colorKey] } );                                            
                        break;
                        case "l":                            
                            partMesh.material = new THREE.MeshStandardMaterial( {
                                color: PartColors[colorKey],
                                metalness: 0.5,
                                roughness: 0.1
                            });                            
                        break;
                        default:
                            partMesh.material = new THREE.MeshBasicMaterial( { color: PartColors[colorKey] } );                                            
                    }                    
                }
            });
        });
    };

    const model = useLoader(FBXLoader, props.path);
    const [hovered, setHover] = useState(false)
    const [partName, setPartName] = useState(false)
    
    setDefaultColors(model);

    const handlePartClicked = e => {        
        if (props.partList[e.object.name]) {
            const partName = props.partList[e.object.name] && props.partList[e.object.name].name !== "" ? props.partList[e.object.name].name : e.object.name;
            state.activeMesh = e.object;//.parent;
            setCurrentPartColor(e.object, "partClicked");
            setHover(true);
            setPartName(partName);    
        }
    };

    const setCurrentPartColor = (part, state) => {        
        props.changePartState(part.name, state);              
    }

    const handlePartPhoto = e => {       
        console.log("Clicked"); 
        console.log(state.activeMesh); 
        if (state.activeMesh) {
            const partName = props.partList[state.activeMesh.name] && props.partList[state.activeMesh.name].name !== "" ? props.partList[state.activeMesh.name].name : state.activeMesh.name;
            console.log("Foto click: "+partName);
            setHover(false);
            setPartName("");             
            props.setViewOption("camera", partName, state.activeMesh.name);   
        }
    };
    const handlePartPhotoCancel = e => {
        if (state.activeMesh) {            
            props.changePartState(state.activeMesh.name, "default");                      
        } 
        setHover(false);
        setPartName("");                                 
    };
 
    return (
        <Suspense fallback={null}>            
            {/* <Dragable transformGroup_>        */}
                <primitive 
                    object={model} 
                    {...props}     
                    onClick={handlePartClicked}     
                />
                {hovered && (
                    <Html scaleFactor={10}>
                        <div id="partInfoBox" className="partNameBox">
                            <div className="partNameBoxContent">
                                Ausgewählt:{partName}
                            </div>
                            <div style={{padding: "5px"}}>                        
                                <button 
                                style={{width: "45%"}}
                                className="btn btn-default btn-block_"
                                onClick={e => {
                                    e.stopPropagation();
                                    handlePartPhotoCancel()
                                }}
                                >Zurück</button>        
                                <button                                 
                                style={{width: "45%", marginRight: "10px"}}
                                className="btn btn-primary btn-block_"
                                onClick={e => {
                                    e.stopPropagation();
                                    handlePartPhoto(e)
                                }}
                                >Foto</button>                                                                    
                            </div>
                        </div>
                    </Html>
            )}
        </Suspense>     
    );
}
export default Model;