import React, {Component} from "react";
import axios from "axios";
import "./FileUpload.css";
import Spinner from "react-bootstrap/esm/Spinner";

class FileUploader extends Component {
    state = {
        isLoading: false,
        standardPhotos: null,
        networkError: false,
        errorMessage: "",
        resultCode: null,
        isUploading : false,
        dataErrors: 0,
        missingImageList: []
    }

    /*
        put all other data the user inouts together into the contract we received from the server (update fields) and send this to the server as well - SOAP
    
    */ 

    getChargeType = () => {
        //chargetype mapper
        let chargeType = -1;
        if (this.props.chargeType === 0 || this.props.chargeType === "0") {
            chargeType = 4;
        } else if (this.props.chargeType === 1 || this.props.chargeType === "1") {
            chargeType = 3;
        }

        return chargeType;
    }

    getChargeTypeString = () => {
        //chargetype mapper
        let chargeType = (            
            <div className="alert alert-warning">Bitte wählen Sie eine Abrechnungsart aus, wenn Sie noch unentschlossen sind, dann können Sie sich auch später entscheiden</div>
        );
        if (this.props.chargeType === 0 || this.props.chargeType === "0") {
            chargeType = "Reparatur";
        } else if (this.props.chargeType === 1 || this.props.chargeType === "1") {
            chargeType = "fiktive Abrechnung, Auszahlung";
        }

        return chargeType;
    }

    onStartTransfer = () => {        
        this.setState({
            isLoading: true
        });
        console.log("Start transfer");        
        let photoList = [];
        this.state.standardPhotos.forEach(photo => {
            let format = photo.metadata.type.split("/");
            if (format.length === 2) {
                format = format[1];
            } else {
                format = "undefined";
            }
            let photoName = this.props.svp+"."+photo.id+"."+format;
            photoList.push(photoName);
        });
            
        let chargeType = this.getChargeType();

        let firstName = "";
        let familyName = "";
        let iban = "";
        if (this.props.bankData !== null) {
            firstName = this.props.bankData.firstname;
            familyName = this.props.bankData.familyname;
            iban = this.props.bankData.iban;
        }        

        //0: kein Vorschaden
        //1: vorschaden
        //2: vorschaden nicht repariert  
        //3: vorschaden repariert  
        let vorschadenStatus = -1;
        if (this.props.vorSchaden === 0 || this.props.vorSchaden === "0") {
            vorschadenStatus = 0;
        } else if (this.props.vorSchaden === 1 || this.props.vorSchaden === "1") {
            vorschadenStatus = 1;
        }
        if (this.props.vorschadenDetail === 0 || this.props.vorschadenDetail === "0") {
            //Der Schaden wurde bereits Instandgesetzt - +Amount
            vorschadenStatus = 3;
        } else if (this.props.vorschadenDetail === 1 || this.props.vorschadenDetail === "1") {
            //Der Schaden wurde nicht repariert
            vorschadenStatus = 2;
        }

        console.log("VorschadenStatus: "+vorschadenStatus);
        console.log("vorschadenDetail: "+this.props.vorschadenDetail);
        console.log("VorschadenAmount: "+this.props.vorschadenAmount);

        const data = new FormData();            
        data.append("bildversand", 1);
        data.append("systemID", this.props.systemID);
        data.append("vorgangsnummer", this.props.contractData.svp);
        data.append("code", this.props.code);
        data.append("name", familyName);
        data.append("vorname", firstName);
        data.append("iban", iban);
        data.append("vorschadenstatus", vorschadenStatus);
        data.append("vorschadenamount", this.props.vorschadenAmount);
        data.append("abrechnungswunschauswahl", chargeType);
        const copartFlag = this.props.choose_copart
        data.append("choose_copart", copartFlag);
        data.append("customer_mail", this.props.customer_mail);
        data.append("customer_phone", this.props.customer_phone);
        
        let testUrl = this.props.uploadTarget+"bilderversand2.php";

        
        let response = axios({
            method: "post",
            url: testUrl,                                
            data: data, 
            // 'Access-Control-Allow-Origin': '*',
            // 'Content-Type': 'application/json',
            headers: {
                "X-Requested-Width": "XMLHttpRequest",
                'Access-Control-Allow-Origin': '*',
                'Content-Type' : `multipart/form-data;`
            },               
        })
        .then(response =>{             
            if (response.data.code !== null) {
                console.log("onStartTransfer(): code: ", response.data.code);
                console.log("onStartTransfer(): message: ", response.data.message);

                this.setState({
                    resultCode: response.data.code,
                    errorMessage: response.data.message,
                    isLoading: false
                });
                this.props.onTransferFinished(true);
            } else {
                console.log("onStartTransfer(): Response: ", response.data);
                console.log("onStartTransfer(): code: ", response.data.code);
                console.log("onStartTransfer(): message: ", response.data.message);                
                this.setState({                    
                    networkError: false,
                    errorMessage: "",
                    isLoading: false
                });
            }
            return response.data
        })
        .catch(response => {
            console.log("Server Exception: ",response);
            response = {
                success: 500,
                message: "Datei konnte nicht übertragen werden",
                data: ""
            }
            this.setState({
                networkError: true,
                errorMessage: response.message,
                isLoading: false
            });          
        }
        );
        
        console.log("Transfer finished");

        //cleanup localStorage
    }

    initializeData = () => {
        let dataComplete = true;
        if (this.props.photoList.length > 0) {
            let standardPhotos = [];
            let curErrors = this.state.dataErrors;
            let missingImageList = this.state.missingImageList;
            this.props.photoList.forEach(photo => {
                if (photo.image != null || photo.fileData != null || photo.uploaded === true) {
                    //&& photo.format !== "video/webm"
                    const fileItem = ({
                        key: this.props.contractData.key,
                        svp: this.props.contractData.gutachten_nr_formatiert,
                        id: photo.id, 
                        format: photo.format,
                        imagedata: photo.image,
                        fileData: photo.fileData,
                        title: photo.title, 
                        uploaded: photo.uploaded,
                        metadata: photo.metadata,
                        status: 0, 
                        progress: 0
                    });
                    standardPhotos = [...standardPhotos, fileItem];
                } else {
                    if (photo.required && photo.image == null && photo.fileData === null && photo.uploaded === false) {
                        dataComplete = false;                            
                        missingImageList.push(photo.title);                                            
                        curErrors = curErrors+1;
                    }
                }
            });

            let message = {
                //className: "alert-warning",
                //message: `Es müssen noch ${missingImageList.length} Dokumente übertragen werden`
            };
            if (missingImageList.length === 0) {
                message = "";
            }            
 
            this.setState({
                standardPhotos: standardPhotos,
                dataErrors: curErrors,
                dataComplete: dataComplete,
                missingImageList: missingImageList,
                message: message
            });
        } 
    }
    
    componentWillMount = () => {
        this.initializeData();
    }

    render() {   
        let buttonDisabled = false;
        if (/* this.getChargeType() === -1 ||  */!this.props.uploadFinished || !this.props.dsgvoAccepted) {
            buttonDisabled = true;
        }
        let transferButton = (
            <div>
                <button type="button" disabled={buttonDisabled}className="btn btn-warning btn-block krugButtonColor" onClick={() => this.onStartTransfer()}>
                    Übertragung starten
                </button>
                <div>Bitte warten Sie bis alle Daten vollständig übertragen sind!</div>
            </div>
        );


        let InfoBox = (
        <div className={"alert "+this.state.message.className}>{this.state.message.message}</div>
        );
        if (!this.state.message.message || this.state.message.message === "") {
            InfoBox = null;
        }
       
        let bankData = null;
        if (this.props.bankData !== null) {
            let accountHolder = "";
            if (this.props.bankData.firstname.length > 0) {
                accountHolder = this.props.bankData.firstname;
            }
            if (this.props.bankData.familyname.length > 0) {
                if (accountHolder.length > 0) {
                    accountHolder = accountHolder+" ";
                }
                accountHolder = accountHolder+this.props.bankData.familyname;
            }
            if (accountHolder.length > 0 ||this.props.bankData.iban.length > 0) {
                bankData = (
                    <div>
                        <div>Kontoinhaber: {accountHolder}</div>
                        <div>IBAN: {this.props.bankData.iban}</div>
                    </div>
                )
            }
        }

        let transferErrorMessage = null;
        if (this.state.resultCode !== null && this.state.resultCode !== 1 && this.state.errorMessage) {
            transferErrorMessage = (
                <div className="row marginTop30">
                    <div className="alert alert-danger col-sm-12">Fehler bei der Übertragung: {this.state.errorMessage}</div>
                </div>
            );
        }

        if (this.props.errorMessage !== null && this.props.errorMessage.length > 0) {
            transferErrorMessage = (
                <div className="row marginTop30">
                    <div className="alert alert-danger col-sm-12">Fehler bei der Übertragung: {this.props.errorMessage}</div>
                </div>
            );
        }

        let transferBox = (
            <div className="row marginTop30">
                {transferErrorMessage}                
                 <div className="col-md-6 col-sm-12">
                        {transferButton}
                    </div>                    
                    <div className="col-md-6 col-sm-12">
                        <button type="button" className="btn btn-warning btn-block _buttonCancel _contolButtons" onClick={this.props.clearAssignementData}>Abbrechen</button>
                        <div>Durch das Betätigen des "Abbrechen" Buttons, werden alle von Ihnen bisher für diesen Vorgang erfassten Dokumente (auch Fotos und Videos) gelöscht</div>
                    </div>
            </div>
        );
        
        let abrechnungsLine = "";
        if (this.getChargeType() === 4 || this.getChargeType() === 3 || this.getChargeType() === -1 || this.getChargeType() === -2) {
            abrechnungsLine = (
                <div className="chargeType">Abrechnung: {this.getChargeTypeString()}</div>
            );
        }

        if (this.state.resultCode !== null) {
            if (this.state.resultCode === 1) {
                transferBox = (
                    <div className="row marginTop30">
                        <div className="alert alert-success col-sm-12">
                            <p>Vielen Dank für die Übertragung Ihrer Daten.<br></br>
                            Wir werden uns umgehend um Ihren Schadenfall kümmern und setzen uns zeitnah mit Ihnen in Verbindung.</p>
                        </div>
                    </div>
                );
                abrechnungsLine = null;
            } else {
               transferBox = (
                    <div className="row marginTop30">
                        <div className="alert alert-danger col-sm-12">Fehler bei der Übertragung: {this.state.errorMessage}</div>
                    </div>
                )
            }
        }

        if (this.state.isLoading === true) {
            transferBox = (            
                <div className="row alert alert-primary">
                    <div className="row col-md-12 marginBottom15 centerBox">Daten werden übertragen, Bitte warten Sie bis der Vorgang abgeschlossen ist</div>
                    <div className="row col-md-12 centerBox">
                        <Spinner  animation="border" role="status">
                            <span className="sr-only" />
                        </Spinner>
                    </div>                
                </div>
            )
        }

            
            let view = (
                <div className="container_">                
                <div className="uploadHeader">{/* <h5>Daten Übertragung</h5> */}</div>
                {abrechnungsLine}
                {bankData}
                {InfoBox}              
                {transferBox}                
            </div>
        );
        if (this.state.networkError) {
            view = (
                <div className="container">                
                    <div className="row">
                        <div className="col-md-3"></div> 
                        <div className="col-md-6 cols-sm-12">
                            <div className="alert alert-danger">{this.state.errorMessage}</div>
                        </div> 
                        <div className="col-md-3"></div> 
                    </div>       
                </div>
            );
        }

        return (
            <div>
                {view}                
            </div>
        )
    }
}

export default FileUploader;
