import React, { useState, useRef } from "react";
import {Camera} from "react-camera-pro";

import uploader from "./Uploader";

import "./CameraComponent.css";

const CameraComponent = (props) => {
  //https://openbase.com/js/react-camera-pro/tutorials
  let showMask = false;
  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [photoDone, setPhotoDone] = useState(false);
  


  const takePhoto = () => {
    console.log("PHOTO");
    const newImage = camera.current.takePhoto();
    let returnFileName = "";
      setPhotoDone(true);
      uploader({image: newImage, 
        partKey: props.partKey, 
        fileName: props.partName, 
        path: props.uploadTarget, 
        id: props.partName, 
        systemID: props.systemID, 
        assKey: props.assKey, 
        svp: props.svp, 
        returnFileName: returnFileName
      });
      console.log("FileName: "+returnFileName);
      props.storePhoto(newImage,  props.partKey, props.partName, returnFileName);      
      setImage(newImage);
  };

  const switchBack = () => {            
    props.changePartState(props.partKey, "default");
    props.setViewOption("model");
  };

  let cameraCSSClass = "cameraMask";
  if (!showMask) {
    cameraCSSClass = "cameraScreen";
  }

  return (
    <div>
      {!photoDone && <div style={{"width": "100%", "height": "100%"}}>
          <button className="btn btn-primary" onClick={takePhoto}> Take photo {props.partName}</button>
          <button className="btn btn-success" onClick={switchBack}> Zurück </button>          
          <div className={cameraCSSClass}>
            <Camera ref={camera} facingMode='environment' aspectRatio={16 / 9}/>
          </div>
        </div>
      }
      {photoDone && <div className={cameraCSSClass}>
        Aufnahme: {props.partName}
          <button className="btn btn-success" onClick={switchBack}> Zurück </button>
          <img src={image} style={{"width": "100%"}} alt='Taken photo'/>        
        </div>
      }
    </div>
  );
}

export default CameraComponent;