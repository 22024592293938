import React, { useRef}  from "react";

//Fiber
import { useFrame } from '@react-three/fiber';

import Carpic from "./components/Carpic/Carpic";
import MessageHeader from "./components/Layout/MessageHeader";
import * as rdd from "react-device-detect";


export default function App() {

    function Scene() {
        const ref = useRef();
        useFrame(() => {
            ref.current.rotation.x += 0.005;
            ref.current.rotation.y += 0.01;
        });

        return (
            <mesh ref={ref}>
                <boxGeometry args={[4, 4, 4]} />
                <meshStandardMaterial color="orange" />
            </mesh>
        );
      }
     
    
    let view = (
        <div className="row">            
            <Carpic />
        </div>
    );

    
    console.log("You are using "+rdd.browserName+" V "+rdd.browserVersion);
    let browserVersion = parseInt(rdd.browserVersion);
    if (rdd.isIOS && browserVersion <= 9) {
        let message = `You are Using ${rdd.browserName} Version ${rdd.browserVersion}. This Version from 2016 is because of Security Issues not supported by our Software. Please try to update your IPhone`;
        view  = (
            <MessageHeader 
                message={message}
                messageClass="alert-warning iosMessag"
            />             
        );
    }

    return (
        view
    );
}