import React, {useEffect} from "react";

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from "react-dropzone-uploader";
import axios from "axios";



import "./PhotoBox.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

const  photoBox = (props) => {
    let imageName = props.image;

    const showPreview = (event) => {
        const photoData = {
            id: props.id,
            title: props.title,
            image: props.image,
            format: props.format,
            description: props.description,
        }
        props.showPreview(photoData);
    }

    const changeView = (event) => {
        const camData= {
            view: "cam",
            id: props.id,
            title: props.title,
            image: props.image,
            format: props.format,
            description: props.description,
            takePhotoHandler: props.takePhotoHandler
        }
        props.changeView(camData);
    }   

    let imageTag = (
        <img onClick={showPreview} className="cardImage center" src={imageName} alt={props.description} />                        
    )

    let fileInfo = false;   

    if (imageName === null || imageName === "undefined" || imageName === undefined) {
        if (props.uploaded && props.metadata !== null) {
            if (props.metadata) {
                fileInfo = true;       
                let modDate = "";
                try {
                    if (props.metadata.lastModifiedDate) {
                        const myDate = Date.parse(props.metadata.lastModifiedDate);
                        modDate = new Intl.DateTimeFormat("de-DE", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit"
                        }).format(myDate);
                    }
                } catch(e) {
                    console.log("PhotoBox(): error while converting date: meta: ", props.metadata);
                }

                imageTag = (
                    <div className="fileInformation">
                        <div className="fileInformationName"><FontAwesomeIcon icon={faCheck} className="checker"/></div>
                        <div className="fileInformationSize">Dateigröße: {props.metadata.size}</div>
                        <div className="fileInformationDate">Datum: {modDate}</div>
                    </div>
                );
            }
        }
    }
    
    if (!imageName && !fileInfo) {
        imageTag = (
            <div onClick={changeView} className="boxDescription">
                {/*props.description*/}
            </div>
        );
    }

    /*Show upload error message*/
    let messages = null;
    if (props.uploadError && props.fileName) {
        messages = (
        <div className="uploadError">Fehler beim Upload der Datei {props.filename}</div>
        )
    }

    // eslint-disable-next-line
    useEffect(() => {
        const video = document.getElementById("videoOverview");
        if (video) {
            
            //video.srcObject = MediaStream(imageName);//new MediaStream({stream: imageName});
        } else {
            //console.log("useEffect: did not set the blob");
        }
    }); 

    const deletePhoto = (event) => {
        const photoData = {
            id: props.id,
            title: props.title,
            image: props.image,
            description: props.description,
        }
        props.deletePhoto(photoData);
    }
   
    
    const handleSubmit = (files, allFiles) => {
        
        allFiles.forEach(f => {
            const formData = new FormData();
            formData.append("action", "put");
            formData.append("systemID", props.systemID);
            formData.append("id", props.id);
            formData.append("key", props.assKey);
            formData.append("format", f.meta.type);
            formData.append("title", props.title);
            formData.append("filename", props.fileName);
            formData.append("svp", props.svp);
            formData.append("file", f.file); 
            
            const photoData = {
                id: props.id,
                format: props.format,
                title: props.title,
                image: f.meta.previewUrl,
                fileData: f,
                url: null
            }
            props.takePhotoHandler(photoData);
            
            axios.post(props.uploadTarget+"upload.php", formData, {
                headers: { 
                    "X-Requested-Width": "XMLHttpRequest",
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type' : `multipart/form-data; boundary=${f._boundary}`}
                }).then ((res) => {
                    console.log("onSubmit response: ",res);
                })            
            }).catch( (error) => {
                console.log("OnSubmit error: ",error);
                alert(error);
            });        
        }
        
        const onDropFile = (files) => {
            //todo
        }
        
        let fileTypes = "image/*";
        let medium = "Foto";
        let fileMaxSize = (1024 * 1024 * props.fileMaxSizeMultiPhoto);
        if (props.format.startsWith("video/")) {
            fileTypes = "video/*";        
            fileMaxSize = (1024 * 1024 * props.fileMaxSizeMultiVideo);
            medium = "Video";
        } else if (props.type === "doc") {
            fileTypes = "application/*,image/*";
        }
        
    const onSkipPhoto = (event) => {        
        const photoId = parseInt(event.target.value);
        console.log("onSkipPhoto: id: "+photoId);
        props.onSkipPhoto(photoId);
    }        
      
    const handleChangeStatus = ({ meta, file }, status) => { 
        console.log("-- handleChangeStatus: ", status);        
        if (status === "done") {
            const photoData = {
                id: props.id,
                format: props.format,
                title: props.title,                
                image: meta.previewUrl,
                fileData: file,
                uploaded: true,
                uploadError: false,
                metadata: meta,
                url: null
            }
            props.takePhotoHandler(photoData);
        } else if (status === "error_file_size") {
            props.onError(props.id, "das Bild überschreitet die maximale Größe");
        } else if (status === "preparing") {
            props.onUploading(props.id);
        } else if (status === "exception_upload" || status === "aborted") {
            const photoData = {
                id: props.id,
                format: props.format,
                title: props.title,                
                fileName: meta.name,
                image: null,
                fileData: null,
                uploadError: true
            }
            props.takePhotoHandler(photoData);
        }
    }
    
    const getUploadParams = ({ file, meta }) => { 
        console.log("-- getUploadParams");
        const body = new FormData()
        body.append("action", "put");
        body.append("systemID", props.systemID);
        body.append("id", props.id);
        body.append("key", props.assKey);
        body.append("format", meta.type);
        body.append("title", props.title);
        body.append("filename", props.fileName);
        body.append("svp", props.contractData.svp);
        body.append("file", file.file); 
        body.append('fileField', file)     

        return { 
            url: props.uploadTarget+"upload.php" , body
        } 
    }
        
    
    let dropBox = (
        <span id={`dropbox_${props.id}`}>
            {messages}
            <Dropzone 
                onDrop={onDropFile}
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                resizeWidth={140}
                maxFiles={1}
                multiple={false}
                maxSizeBytes={fileMaxSize}
                accept={fileTypes}
                inputContent=""
                autoUpload={true}
                />                           
        </span>
    );
    
    
    let boxAction = null;
    let messageText = (
        "Ziehen Sie die Datei hier hinein oder machen Sie ein Foto"
        );

    if (props.format.startsWith("video/")) {
        messageText = (
            "Ziehen Sie die Datei hier hinein oder machen Sie ein Video"
            );
    }
    
    if (props.image != null || fileInfo) {
        dropBox = imageTag;        
        messageText = (
            <div className="deletePhotoBoxPic" onClick={deletePhoto}>
            {medium} löschen
        </div>
        )
        boxAction = deletePhoto;
    }
    
    let className = "";    
    let checkCardBoxFooter = "";
    let checkCardBoxTitle = "";
    let checkCardBoxHeight = "";
    let minHeight= 200;
    if (props.className) {
        className = props.className;
        checkCardBoxFooter = props.className+"Footer";
        checkCardBoxTitle = props.className+"Title";
        checkCardBoxHeight = props.className+"Height";
        minHeight = 100;
    }
    
    let backgroundStyle = null;
    if (props.icon !== null && props.metadata === null) {
        backgroundStyle = {            
            backgroundImage: `url(${process.env.PUBLIC_URL+props.icon})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            maxHeight: minHeight+"px",
        };
    }

    let errorBox = null;
    if (props.generalError && props.generalError === props.id) {
        errorBox = <div className="alert alert-danger">{props.errorMessage}</div>
    }

    let skipUploadItem = (
        <div>
        {props.required && props.skipbtn && <button className="bnt btn-warning btn-block" value={props.id} onClick={onSkipPhoto}>nicht fotografierbar</button>}
        </div>
    );

    return (                        
        <div className={className}>
            <div className="card gridCard " id={"box_"+props.id}>
                <div className="card-header pictureCardHeader">
                    <h5 className={"card-title photoCardTitle "+checkCardBoxTitle}>{props.title}</h5> 
                </div>
                <div className={"card-body pictureCardBody "+checkCardBoxHeight+" "+ checkCardBoxFooter+ " "+((!props.uploaded && props.required) ? "photoNotComplete" : "photoComplete")} style={backgroundStyle}>
                    {dropBox}
                </div> 
                <div className={"card-footer pictureCardFooter "+checkCardBoxFooter} onClick={boxAction}>
                    {messageText}
                </div>
                {skipUploadItem}               
            </div> 
            {errorBox}                                  
        </div>
    );
}

export default photoBox;