import React,  { Component } from "react";
import Lights from "./Lights"
import {Canvas} from "@react-three/fiber"
import { ContactShadows, OrbitControls } from '@react-three/drei';
import Model from "./Model";

import CameraComponent from "./CameraComponent";
import PartPhotos from "./PartPhotos";

import "./ClickableCar.css"


//?key=ONXTBFKA
/*
Und hier die Farben vom Clickable Car:
    selected: '#4674A9',
    hover: '#B0CFFA',
    glass: '#D8E4E0', 
    backlight: '#FFC2C2',
    frontlight: '#EEDD82',
    wheels: '#111111',
    rims: '#A8A9AD',
    grill: '#E6E6E6',
    default: '#F5F7F2',

*/ 
class ClickableCar extends Component {
    constructor (props) {
        super(props)        
        this.state = { 
            viewOption: "model",
            currentPart: null
        }
    }

    
        
    setViewOption = (option, currentPart, currentPartKey) => {
        if (option.length === 0) {
            return;
        }
        this.setState({
            viewOption: option,
            currentPart: currentPart,
            currentPartKey: currentPartKey
        });
    }

    storePhoto = (photo, partKey, partName, fileName) => {        
        console.log("storePhoto: "+partName);
        this.props.takePartPhotoHandler(partKey, fileName, "partSelected");
        this.setState({
            viewOption: "model"
        });
    };

    render() {        
        console.log("PARTLIST");
        console.log(this.props.partList);
        let view = (
            // <div>
            <div >
                <div className="formatText clickableCarDescription">
                    Bitte grenzen Sie die Schaden Örtlichkeit, in dem Sie das schadhafte Bauteil markieren und anschließend fotografieren, ein.
                </div>
                <Canvas                       
                    className="threebox"
                    flat
                    shadows
                    width="100%"       
                    dpr={[1, 1.5]}                    
                    camera={{
                        fov: 55,
                        aspect: window.innerWidth / window.innerHeight,
                        position: [10,1,5],
                        // position: [0,0,15],
                        // target: [10,5,10]
                    }}                   
                    >
                    {/* <color attach="background" args={["#f0f0f0"]} /> */}
                    <ContactShadows resolution={1024} frames={1} position={[0, -0.2, 0]} scale={15} blur={0.6} opacity={0.6} far={20} />
                    {/* <mesh scale={20} position={[3. -1.161, -1.5]} rotation={[-Math.PI /2, 0, Math.PI /2.5]} >
                        <ringGeometry args={[0.9, 1, 4, 1]} />
                        <meshStandardMaterial color="white" roughness={0.75} />
                    </mesh> */}
                    <OrbitControls 
                        // target={[0, 1.2/*.35*/, 0]} 
                        enablePan={false}
                        enableZoom={false}
                        minPolarAngle={Math.PI / 3.5}
                        maxPolarAngle={Math.PI / 2.7} 
                        attach="OrbitControls"
                        // target= {[0,0,-1]}
                    />
                    <Lights />      
                    {/* <Environment resolution={512}>
                       <Lightformer form="ring" color="red" intensity={10} poition={[10, 5, 10]} scale={2} />
                     </Environment> */}
                    <Model 
                        path="/clickablecar/Limousine.fbx" 
                        scale={new Array(3).fill(0.024)}
                        position={[0, 0, 0]}   
                        rotation={[-Math.PI/2,0, Math.PI/2.5]}                       
                        viewOption={this.state.viewOption}
                        setViewOption={this.setViewOption}    
                        changePartState={this.props.changePartState}                                         
                        partList={this.props.partList}
                    />                               
                </Canvas>
                <PartPhotos 
                    partList={this.props.partList}
                    uploadTarget={this.props.uploadTarget}
                />
            </div>  
            // </div>
        );

        if (this.state.viewOption === "camera") {
            view = <CameraComponent                 
                partName={this.state.currentPart}
                partKey={this.state.currentPartKey}
                systemID={this.props.systemID}
                svp={this.props.svp}
                assKey={this.props.assKey}
                storePhoto={this.storePhoto}
                viewOption={this.state.viewOption}
                setViewOption={this.setViewOption}  
                changePartState={this.props.changePartState}
                uploadTarget={this.props.uploadTarget}
                />
        }
        return (
            <div>
                {view}
            </div>
        );
    };
}

export default ClickableCar;