import React from "react";

import Assignement from "../Assignement/Assignement";
import Header from "./Header";
import "./Layout.css";

//import Jitsi from "../Jitsi/jitsi.component";
//import Jitsi from 'react-jitsi'

const getErrorMessage = (error) => {
    
    switch(error) {       
        case '10001':
            return 'Diese Datei existiert bereits';
        case '10002':
            return "Diese Datei konnte nicht angelegt werden: ";
        case '10003':
            return "In Datei konnte nicht geschrieben werden: ";
        // Datenbank
        case '20001':
            return "Fehler beim Schreiben in die Datenbank: ";
        case '20002':
            return "Dieses Gutachten existiert nicht: ";
        case '20003':
            return "Die Liste der Gutachten kann nicht erstellt werden.";
        case '20004':
            return "Benutzername und/oder Passwort falsch.";
        case '20005':
            return "Der Gutachtenschlüssel konnte nicht ermittelt werden";
        case '20006':
            return "Kein Gutachten für diesen Benutzer verfügbar.";
        // XML
        case '30001':
            return "Fehler beim Einlesen der XML Datei.";
        case '30002':
            return "Die Datei wurde beim Übertragen beschädigt.";
        // CarPic
        case '40001':
            return 'Schaden-Typ des Auftrags ist nicht Privathaftpflicht.';
        case '40002':
            return 'Für diesen Auftrag wurden keine Fotos angefragt.';
        case '40003':
            return 'Ungültiger Schlüssel.';
        case '40004':
            return 'Zu diesem Vorgang wurden bereits Bilder hochgeladen.';
        case '40005':
            return 'Das Ablaufdatum wurde überschritten.';
        default: return error;
    }
}

const layout = (props) => {        
    
    let logo = process.env.PUBLIC_URL+"/images/krug_logo_transparent.png"
    let alttext = "SV-Krug GmbH Logo";
    let titleText = "";
    let noLogo = false;
    let injuranceLogo = props.injurenceLogo;
    if (injuranceLogo && injuranceLogo !== null) {
        injuranceLogo = injuranceLogo.trim();        
    }
    if (injuranceLogo && injuranceLogo.length > 0 && injuranceLogo !== "undefined") {
        logo = injuranceLogo;
    } else {
        noLogo = true;
    }
    if (props.injuranceName && props.injuranceName.length > 0) {
        alttext = props.injuranceName;
        if (noLogo) {
            titleText = props.injuranceName;
        }
    }

    //https://github.com/ivicos-GmbH/react-jitsi
    // const roomName = "krugtest2";
    // const userFullName = "Georg Stein";
    // const jitsiServer = "svmeet.svpro.de";
    // const jitsiConfig = {
    //     prejoinPageEnabled: false, 
    //     startWithAudioMuted: true,
    //     toolbarButtons: ['microphone', 'camera','chat','hangup', 'settings', 'desktop']
    // };
    // const jitsiContainerStyle = { display: "flex", alignItems: "center", justifyContent: "center" };
    // const jitsiFrameStyle = {width: '100%',height: '100%'};
    // const jitsiLoadingComponent = "";//<div>Verbindung zu SV-Krug wird aufgebaut</div>;    

    // let jitsiBox =  (
    //         <div className="row">
    //             <div className="col-sm-2 col-md-2">&nbsp;</div>                               
    //             <Jitsi 
    //                 onHangup={console.log("hangup pressed")}
    //                 readyToClose={console.log("ready to close pressed")}
    //                 className="col-sm-8 col-md-8" 
    //                 config={jitsiConfig} 
    //                 loadingComponent={jitsiLoadingComponent} 
    //                 containerStyle={jitsiContainerStyle} 
    //                 frameStyle={jitsiFrameStyle} 
    //                 roomName={roomName} 
    //                 displayName={userFullName} 
    //                 domain={jitsiServer}
    //                 hangupManager={hangupManager}
    //             />
    //             <div className="col-sm-2 col-md-2">&nbsp;</div>               
    //         </div>  
    // ); 
    
    // if (!props.jitsiStarted) {
    //     jitsiBox = (
    //         <div className="row">
    //             <div className="col-sm-2 col-md-2">&nbsp;</div>                               
    //             <button className="btn btn-warning btn-block" onClick={props.onVideoChat}>
    //                 Video Chat
    //             </button>
    //             <div className="col-sm-2 col-md-2">&nbsp;</div>               
    //         </div>  
    //     );
    // }
    
    let layoutMain = (
        <div>
            <Header 
            contractData={props.contractData} 
            fileMaxSizeMultiPhoto={props.fileMaxSizeMultiPhoto}
            fileMaxSizeMultiVideo={props.fileMaxSizeMultiVideo}
            standardPhotosTexts={props.standardPhotosTexts}
            haftpflicht={props.haftpflicht}
            svp={props.svp}
            />               
            
            <Assignement 
                appInitialized={props.appInitialized}
                systemID={props.systemID}
                assKey={props.assKey}
                svp={props.svp}
                uploadTarget={props.uploadTarget}
                fileMaxSizeMultiPhoto={props.fileMaxSizeMultiPhoto}
                fileMaxSizeMultiVideo={props.fileMaxSizeMultiVideo}
                contractData={props.contractData} 
                standardPhotos={props.standardPhotos}             
                damageDetailPhotos={props.damageDetailPhotos}
                documents={props.documents}                
                takePhotoHandler={props.takePhotoHandler}
                changePartState={props.changePartState}
                onSkipPhoto={props.onSkipPhoto}
                // carTypes={props.carTypes}
                // carType={props.carType}
                changeView={props.changeView}
                openPhoto={props.openPhoto}
                showPreview={props.showPreview}
                sendAssignementData={props.sendAssignementData}
                clearAssignementData={props.clearAssignementData}
                deletePhoto={props.deletePhoto}
                onChangeContractData={props.onChangeContractData}
                addDamageDetail={props.addDamageDetail}
                damageDetailAddButton={props.damageDetailAddButton}
                moreButtons={props.moreButtons}
                addDocuments={props.addDocuments}
                documentAddButton={props.documentAddButton}
                dsgvoAccepted={props.dsgvoAccepted}
                onAcceptDsgvo={props.onAcceptDsgvo}
                onChangechargeType={props.onChangechargeType}
                vorSchaden={props.vorSchaden}
                vorschadenDetail={props.vorschadenDetail}                
                vorschadenAmount={props.vorschadenAmount}
                onChangeVorschaden={props.onChangeVorschaden}
                chargeType={props.chargeType}
                onChangeBankData={props.onChangeBankData}
                bankData={props.bankData}
                uploadFinished={props.uploadFinished}  

                modalVisible={props.modalVisible}
                setModalVisible={props.setModalVisible}

                                 
                jobCanceled={props.jobCanceled}  
                onError={props.onError}    
                generalError={props.generalError}                            
                errorMessage={props.errorMessage}
                error={props.error}     
                transferFinished={props.transferFinished}
                onTransferFinished={props.onTransferFinished}

                isUploading={props.isUploading}
                onUploading={props.onUploading}
                checkIfUploading={props.checkIfUploading}
                auszahlung_wbw_netto={props.auszahlung_wbw_netto}
                choose_copart={props.choose_copart}
                customer_mail={props.customer_mail}    
                customer_phone={props.customer_phone}    
                onChangeData={props.onChangeData}
                partList={props.partList}
                takePartPhotoHandler={props.takePartPhotoHandler}
            /> 
            {/* {jitsiBox} */}
        </div>
    );

    if (props.error) {
        layoutMain = (
        <div className="alert alert-warning errorMessageBox">{getErrorMessage(props.errorMessage)}</div>
        );
    }
    
    let viewData = (
        <div className="container">
            <div className="headerLogoBox">
                <p className="headerLogoText">Im Auftrag von</p>   
                <p className="vuName">{titleText}</p>             
                <img className="App-logo" src={logo} alt={alttext}></img>
            </div>
             <div className="layoutHeader">&nbsp;</div>
                <div className="container mainLayout">            
                    {layoutMain}
                </div>
            <div className="layoutFooter">&nbsp;</div>

            <div className="appFooter">
                <div>&nbsp;</div>
                <div className="appFooterLogoBox">
                    <p className="appFooterHead">Ihr Ansprechpartner:</p>
                    <div >                    
                        <a href="https://www.sv-krug.de" target="_blank" rel="noopener noreferrer"><img  className="appFooterLogo" src={process.env.PUBLIC_URL+"/images/krug_logo_transparent.png"} alt="SV-Krug GmbH Logo"></img></a>
                    </div>
                    <p className="appFooterFoot"><a href="tel:+496838986180" className="tapiLink">Telefon: 06838 98618‐0</a></p>                    
                </div>
                <div>&nbsp;</div>                
            </div>
            <hr className="endLine" />
            <div className="endLineControls">                
                <div></div>
                <a href="https://sv-krug.de/de/Impressum" target="_blank" rel="noopener noreferrer" className="mainInfoButton" >Impressum</a>
                <div></div>
                <a href="https://sv-krug.de/de/Datenschutz" target="_blank" rel="noopener noreferrer" className="mainInfoButton">Datenschutzerklärung</a>
                <div></div>
            </div>
            <div className="layoutFooter">&nbsp;</div>
        </div>        
    )

    if (props.view && props.view === "cam") {
        viewData = (
            <div className="container mainLayout">            
                <Header />               
                CAMERA
            </div>
        )
    }

    return viewData;

};

export default layout;