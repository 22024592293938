import React from "react";

const PartPhotos = (props) => {    
    let photosPresent = false;    
    let counter = 0;
    let photosTaken = (
        <div>
            <div className="partFotoHeader">Partfotos</div>
            {Object.keys(props.partList).map((key, index) => {
                if (props.partList[key].taken) {
                    photosPresent = true;
                    counter++;                    
                    return <span className="partFotoEntry">{counter > 1 && ", "}{props.partList[key].name}</span>
                }
            })}        
        </div>
    );

    if (!photosPresent) {
        photosTaken = null;
    }
    return (
        photosTaken
    );
}

export default PartPhotos;